import * as React from "react"
import { Layout } from "../components/layout"
import { Card, Container } from "react-bootstrap"
import useNieuwsItems from "../hooks/useNieuwsItems"
import { ArrowRightCircle } from "react-bootstrap-icons"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Seo } from "../components/seo"

export default function Nieuws() {
  const nieuws = useNieuwsItems();

  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center">Nieuws</h1>
        {
          nieuws.map(item => {
            return (
              <Card className="mb-6">
                <Card.Body>
                  <Card.Text>
                    {item.body}
                  </Card.Text>
                  {
                    item.frontmatter?.ctaText && (
                      <OutboundLink className="mt-2 fw-bold d-flex" href={item.frontmatter?.ctaLink}>
                        <span>{item.frontmatter?.ctaText}</span>
                        <ArrowRightCircle className="ms-1 align-self-center" />
                      </OutboundLink>
                    )
                  }
                </Card.Body>
              </Card>
            )
          })
        }
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Nieuws"
  />
)
