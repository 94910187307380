import * as React from "react"

export default function useNieuwsItems() {
  return [
    {
      id: "facebook",
      frontmatter: {
        ctaText: "Facebook",
        ctaLink: "https://www.facebook.com/zelfgemaakthasselt",
      },
      body: (
        <>
          <h2>Zelfgemaakt lekkers</h2>
          <p>Wil je op de hoogte blijven van ons laatste nieuws, nieuwe producten, markten waar we aanwezig zijn. Volg dan zeker onze Facebook pagina.</p>
        </>
      )
    }
  ]
}
